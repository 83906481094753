import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { i18nextPlugin } from "translation-check";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import EMAIL_VERIFICATION_EN from "./en/email-verification.json";
import EMAIL_VERIFICATION_FR from "./fr/email-verification.json";
import WHAT_TO_EXPECT_EN from "./en/what-to-expect.json";
import WHAT_TO_EXPECT_FR from "./fr/what-to-expect.json";
import PERSONAL_INFO_EN from "./en/personal-info.json";
import PERSONAL_INFO_FR from "./fr/personal-info.json";
import BUSINESS_INFO_EN from "./en/business-info.json";
import BUSINESS_INFO_FR from "./fr/business-info.json";
import ANNUAL_REVENUE_EN from "./en/annual-revenue.json";
import ANNUAL_REVENUE_FR from "./fr/annual-revenue.json";
import CEBA_LOAN_INFO_EN from "./en/ceba-loan-info.json";
import CEBA_LOAN_INFO_FR from "./fr/ceba-loan-info.json";
import INFORMATION_SUMMARY_EN from "./en/information-summary.json";
import INFORMATION_SUMMARY_FR from "./fr/information-summary.json";
import BANKING_INFO_EN from "./en/banking-info.json";
import BANKING_INFO_FR from "./fr/banking-info.json";
import UPLOAD_DOCUMENTS_EN from "./en/upload-documents.json";
import UPLOAD_DOCUMENTS_FR from "./fr/upload-documents.json";
import UPLOAD_ID_VERIFICATION_EN from "./en/upload-id-verification.json";
import UPLOAD_ID_VERIFICATION_FR from "./fr/upload-id-verification.json";
import PROCESSING_DOCUMENTS_EN from "./en/processing-documents.json";
import PROCESSING_DOCUMENTS_FR from "./fr/processing-documents.json";
import ABANDONED_APPLICATION_EN from "./en/abandoned-application.json";
import ABANDONED_APPLICATION_FR from "./fr/abandoned-application.json";
import DECLINED_APPLICATION_EN from "./en/declined-application.json";
import DECLINED_APPLICATION_FR from "./fr/declined-application.json";
import ERROR_APPLICATION_EN from "./en/error-application.json";
import ERROR_APPLICATION_FR from "./fr/error-application.json";
import EXISTING_APPLICATION_EN from "./en/existing-application.json";
import EXISTING_APPLICATION_FR from "./fr/existing-application.json";
import MANUAL_APPROVAL_EN from "./en/manual-approval.json";
import MANUAL_APPROVAL_FR from "./fr/manual-approval.json";
import PENDING_APPROVAL_EN from "./en/pending-approval.json";
import PENDING_APPROVAL_FR from "./fr/pending-approval.json";
import PENDING_CREDIT_PULL_EN from "./en/pending-credit-pull.json";
import PENDING_CREDIT_PULL_FR from "./fr/pending-approval.json";
import MANUAL_REVIEW_EN from "./en/manual-review.json";
import MANUAL_REVIEW_FR from "./fr/manual-review.json";
import APPROVED_APPLICATION_EN from "./en/approved-application.json";
import APPROVED_APPLICATION_FR from "./fr/approved-application.json";
import CEBA_COMMITMENT_EN from "./en/ceba-commitment.json";
import CEBA_COMMITMENT_FR from "./fr/ceba-commitment.json";
import ID_VERIFICATION_EN from "./en/id-verification.json";
import ID_VERIFICATION_FR from "./fr/id-verification.json";
import CORPORATE_VERIFICATION_EN from "./en/corporate-verification.json";
import CORPORATE_VERIFICATION_FR from "./fr/corporate-verification.json";
import CONFIRM_CEBA_ACCOUNT_EN from "./en/confirm-ceba-account.json";
import CONFIRM_CEBA_ACCOUNT_FR from "./fr/confirm-ceba-account.json";
import ACKNOWLEDGEMENT_EN from "./en/acknowledgement.json";
import ACKNOWLEDGEMENT_FR from "./fr/acknowledgement.json";
import SIGN_AGREEMENT_EN from "./en/sign-agreement.json";
import SIGN_AGREEMENT_FR from "./fr/sign-agreement.json";
import CONFIRMATION_EN from "./en/confirmation.json";
import CONFIRMATION_FR from "./fr/confirmation.json";
import SIN_ENTRY_EN from "./en/sin-entry.json";
import SIN_ENTRY_FR from "./fr/sin-entry.json";
import FAILED_STATEMENTS_VERIFICATION_EN from "./en/failed-statements-verification.json";
import FAILED_STATEMENTS_VERIFICATION_FR from "./fr/failed-statements-verification.json";
import DOCUMENT_TITLE_EN from "./en/document-title.json";
import DOCUMENT_TITLE_FR from "./fr/document-title.json";
import ADMIN_LOGIN_EN from "./en/admin-login.json";
import ADMIN_LOGIN_FR from "./en/admin-login.json";
import SUBMITTED_APPLICATION_EN from "./en/submitted-application.json";
import SUBMITTED_APPLICATION_FR from "./fr/submitted-application.json";
import EXISTING_BALANCE_EN from "./en/existing-balance.json";
import EXISTING_BALANCE_FR from "./fr/existing-balance.json";
import DEADLINE_PASSED_EN from "./en/deadline-passed.json";
import DEADLINE_PASSED_FR from "./fr/deadline-passed.json";

const resources = {
  en: {
    documentTitle: DOCUMENT_TITLE_EN,
    emailVerification: EMAIL_VERIFICATION_EN,
    whatToExpect: WHAT_TO_EXPECT_EN,
    personalInfo: PERSONAL_INFO_EN,
    businessInfo: BUSINESS_INFO_EN,
    annualRevenue: ANNUAL_REVENUE_EN,
    cebaLoanInfo: CEBA_LOAN_INFO_EN,
    informationSummary: INFORMATION_SUMMARY_EN,
    bankingInfo: BANKING_INFO_EN,
    uploadDocuments: UPLOAD_DOCUMENTS_EN,
    uploadIdVerification: UPLOAD_ID_VERIFICATION_EN,
    processingDocuments: PROCESSING_DOCUMENTS_EN,
    abandonedApplication: ABANDONED_APPLICATION_EN,
    declinedApplication: DECLINED_APPLICATION_EN,
    errorApplication: ERROR_APPLICATION_EN,
    existingApplication: EXISTING_APPLICATION_EN,
    manualApproval: MANUAL_APPROVAL_EN,
    pendingApproval: PENDING_APPROVAL_EN,
    pendingCreditPull: PENDING_CREDIT_PULL_EN,
    manualReview: MANUAL_REVIEW_EN,
    approvedApplication: APPROVED_APPLICATION_EN,
    cebaCommitment: CEBA_COMMITMENT_EN,
    idVerification: ID_VERIFICATION_EN,
    corporateVerification: CORPORATE_VERIFICATION_EN,
    confirmCebaAccount: CONFIRM_CEBA_ACCOUNT_EN,
    acknowledgement: ACKNOWLEDGEMENT_EN,
    signAgreement: SIGN_AGREEMENT_EN,
    confirmation: CONFIRMATION_EN,
    sinEntry: SIN_ENTRY_EN,
    failedStatementsVerification: FAILED_STATEMENTS_VERIFICATION_EN,
    adminLogin: ADMIN_LOGIN_EN,
    submittedApplication: SUBMITTED_APPLICATION_EN,
    existingBalance: EXISTING_BALANCE_EN,
    deadlinePassed: DEADLINE_PASSED_EN,
  },
  fr: {
    documentTitle: DOCUMENT_TITLE_FR,
    emailVerification: EMAIL_VERIFICATION_FR,
    whatToExpect: WHAT_TO_EXPECT_FR,
    personalInfo: PERSONAL_INFO_FR,
    businessInfo: BUSINESS_INFO_FR,
    annualRevenue: ANNUAL_REVENUE_FR,
    cebaLoanInfo: CEBA_LOAN_INFO_FR,
    informationSummary: INFORMATION_SUMMARY_FR,
    bankingInfo: BANKING_INFO_FR,
    uploadDocuments: UPLOAD_DOCUMENTS_FR,
    uploadIdVerification: UPLOAD_ID_VERIFICATION_FR,
    processingDocuments: PROCESSING_DOCUMENTS_FR,
    abandonedApplication: ABANDONED_APPLICATION_FR,
    declinedApplication: DECLINED_APPLICATION_FR,
    errorApplication: ERROR_APPLICATION_FR,
    existingApplication: EXISTING_APPLICATION_FR,
    manualApproval: MANUAL_APPROVAL_FR,
    pendingApproval: PENDING_APPROVAL_FR,
    pendingCreditPull: PENDING_CREDIT_PULL_FR,
    manualReview: MANUAL_REVIEW_FR,
    approvedApplication: APPROVED_APPLICATION_FR,
    cebaCommitment: CEBA_COMMITMENT_FR,
    idVerification: ID_VERIFICATION_FR,
    corporateVerification: CORPORATE_VERIFICATION_FR,
    confirmCebaAccount: CONFIRM_CEBA_ACCOUNT_FR,
    acknowledgement: ACKNOWLEDGEMENT_FR,
    signAgreement: SIGN_AGREEMENT_FR,
    confirmation: CONFIRMATION_FR,
    sinEntry: SIN_ENTRY_FR,
    failedStatementsVerification: FAILED_STATEMENTS_VERIFICATION_FR,
    adminLogin: ADMIN_LOGIN_FR,
    submittedApplication: SUBMITTED_APPLICATION_FR,
    existingBalance: EXISTING_BALANCE_FR,
    deadlinePassed: DEADLINE_PASSED_FR,
  },
};

i18n
  .use(initReactI18next)
  .use(i18nextPlugin)
  .use(I18nextBrowserLanguageDetector)
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
