import { Route } from "./routes-props";
import { lazy } from "react";

const EmailVerification = lazy(() => import("../pages/email-verification"));
const PersonalInfo = lazy(() => import("../pages/personal-info"));
const BusinessInfo = lazy(() => import("../pages/business-info"));
const BankingInfo = lazy(() => import("../pages/banking-info"));
const PendingApproval = lazy(() => import("../pages/pending-approval"));
const PendingCreditPull = lazy(() => import("../pages/pending-credit-pull"));
const ManualApproval = lazy(() => import("../pages/manual-approval"));
const ExistingApplication = lazy(() => import("../pages/existing-application"));
const ManualReview = lazy(() => import("../pages/manual-review"));
const DeclinedApplication = lazy(() => import("../pages/declined-application"));
const ApprovedApplication = lazy(() => import("../pages/approved-application"));
const Summary = lazy(() => import("../pages/summary"));
const IdVerification = lazy(() => import("../pages/id-verification"));
const Confirmation = lazy(() => import("../pages/confirmation"));
const CebaLoanInfo = lazy(() => import("../pages/ceba-loan"));
const CebaCommitment = lazy(() => import("../pages/ceba-commitment"));
const AbandonedApplication = lazy(
  () => import("../pages/abandoned-application")
);
const ErrorApplication = lazy(() => import("../pages/error-application"));
const CorporateVerification = lazy(
  () => import("../pages/corporate-verification")
);
const InformationSummary = lazy(() => import("../pages/information-summary"));
const SignAgreement = lazy(() => import("../pages/sign-agreement"));
const ConfirmCebaAccount = lazy(() => import("../pages/confirm-ceba-account"));
const SinEntry = lazy(() => import("../pages/sin-entry"));
const UploadDocuments = lazy(() => import("../pages/upload-documents"));
const ProcessingDocuments = lazy(() => import("../pages/processing-documents"));
const Acknowledgement = lazy(() => import("../pages/acknowledgement"));
const FailedStatementsVerification = lazy(
  () => import("../pages/failed-statements-verification")
);
const AnnualRevenue = lazy(() => import("../pages/annual-revenue"));
const WhatToExpect = lazy(() => import("../pages/what-to-expect"));
const AdminLogin = lazy(() => import("../pages/admin-login"));
const SubmittedApplication = lazy(
  () => import("../pages/submitted-application")
);
const ExistingBalance = lazy(() => import("../pages/existing-balance"));
const DeadlinePassed = lazy(() => import("../pages/deadline-passed"));

const routes: Route[] = [
  {
    path: "/",
    name: "Email Verification",
    component: EmailVerification,
    exact: true,
  },
  {
    path: "/deadline-passed",
    name: "Deadline Passed",
    component: DeadlinePassed,
    exact: true,
  },
  {
    path: "/what-to-expect",
    name: "What To Expect",
    component: WhatToExpect,
    exact: true,
  },
  {
    path: "/personal-info",
    name: "Personal Information",
    component: PersonalInfo,
    exact: true,
  },
  {
    path: "/business-info",
    name: "Business Information",
    component: BusinessInfo,
    exact: true,
  },
  {
    path: "/annual-revenue",
    name: "Annual Revenue",
    component: AnnualRevenue,
    exact: true,
  },
  {
    path: "/ceba-loan-info",
    name: "CEBA Loan Info",
    component: CebaLoanInfo,
    exact: true,
  },
  {
    path: "/information-summary",
    name: "Information Summary",
    component: InformationSummary,
    exact: true,
  },
  {
    path: "/banking-info",
    name: "Banking Information",
    component: BankingInfo,
    exact: true,
  },
  {
    path: "/upload-documents",
    name: "Upload Documents",
    component: UploadDocuments,
    exact: true,
  },
  {
    path: "/processing-documents",
    name: "Processing Documents",
    component: ProcessingDocuments,
    exact: true,
  },
  {
    path: "/pending-approval",
    name: "Pending Approval",
    component: PendingApproval,
    exact: true,
  },
  {
    path: "/pending-credit-pull",
    name: "Pending Credit Pull",
    component: PendingCreditPull,
    exact: true,
  },
  {
    path: "/sin-entry",
    name: "Sin Entry",
    component: SinEntry,
    exact: true,
  },
  {
    path: "/manual-approval",
    name: "Manual Approval",
    component: ManualApproval,
    exact: true,
  },
  {
    path: "/existing-application",
    name: "Existing Application",
    component: ExistingApplication,
    exact: true,
  },
  {
    path: "/manual-review",
    name: "Manual Review",
    component: ManualReview,
    exact: true,
  },
  {
    path: "/declined-application",
    name: "Declined Application",
    component: DeclinedApplication,
    exact: true,
  },
  {
    path: "/submitted-application",
    name: "Submitted Application",
    component: SubmittedApplication,
    exact: true,
  },
  {
    path: "/approved-application",
    name: "Approved Application",
    component: ApprovedApplication,
    exact: true,
  },
  {
    path: "/ceba-commitment",
    name: "Ceba Commitment",
    component: CebaCommitment,
    exact: true,
  },
  {
    path: "/abandoned-application",
    name: "Abandoned Application",
    component: AbandonedApplication,
    exact: true,
  },
  {
    path: "/error-application",
    name: "Error Application",
    component: ErrorApplication,
    exact: true,
  },
  {
    path: "/existing-balance",
    name: "Existing Balance",
    component: ExistingBalance,
    exact: true,
  },
  {
    path: "/failed-statements-verification",
    name: "Failed Statements Verification",
    component: FailedStatementsVerification,
    exact: true,
  },
  {
    path: "/summary",
    name: "Summary",
    component: Summary,
    exact: true,
  },
  {
    path: "/admin",
    name: "Admin Login",
    component: AdminLogin,
    exact: true,
  },
  {
    path: "/id-verification",
    name: "Id Verification",
    component: IdVerification,
    exact: true,
  },
  {
    path: "/corporate-verification",
    name: "Corporate Verification",
    component: CorporateVerification,
    exact: true,
  },
  {
    path: "/sign-agreement",
    name: "Sign Agreement",
    component: SignAgreement,
    exact: true,
  },
  {
    path: "/acknowledgement",
    name: "Acknowledgement",
    component: Acknowledgement,
    exact: true,
  },
  {
    path: "/confirm-ceba-account",
    name: "Confirm Ceba Account",
    component: ConfirmCebaAccount,
    exact: true,
  },
  {
    path: "/confirmation",
    name: "Confirmation",
    component: Confirmation,
    exact: true,
  },
];

export default routes;
