import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import type {} from "@mui/x-date-pickers/themeAugmentation";

const theme = createTheme({
  palette: {
    primary: {
      main: "#67C288",
    },
    secondary: {
      main: "#005587",
    },
    error: {
      main: red[600],
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          maxWidth: "100%",
        },
      },
    },
    MuiPickersYear: {
      styleOverrides: {
        yearButton: {
          "&.Mui-selected:focus": {
            backgroundColor: "#67C288",
            color: "white",
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          "&.Mui-selected:focus": {
            backgroundColor: "#67C288",
            color: "white",
          },
        },
      },
    },
  },
});

export default theme;
