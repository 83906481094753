import Smartlook from "smartlook-client";
import i18n from "../locale/i18n";

export const isDeepEqual = (object1: any, object2: any) => {
  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) return false;

  for (var key of objKeys1) {
    const value1 = object1[key];
    const value2 = object2[key];

    const isObjects = isObject(value1) && isObject(value2);

    if (
      (isObjects && !isDeepEqual(value1, value2)) ||
      (!isObjects && value1 !== value2)
    ) {
      return false;
    }
  }
  return true;
};

export const track = (eventName: string) => {
  Smartlook.track(eventName, {});
};

export const checkIfTokenIsExpired = (token: string) => {
  const { payload } = jwtDecode<{ exp: number }>(token);
  const expired = Date.now() >= payload.exp * 1000;
  if (expired) localStorage.removeItem("token");
  return expired;
};

const jwtDecode = <T,>(token: string) => {
  const header = JSON.parse(window.atob(token.split(".")[0]));
  const payload = JSON.parse(window.atob(token.split(".")[1])) as T;
  return { raw: token, header, payload };
};

const isObject = (object: any) => {
  return object != null && typeof object === "object";
};

export const formatPhoneNumber = (phone: string) =>
  phone.replace(
    /(\d*)(\d{3})(\d{3})(\d{4})$/,
    (s, a, b, c, d) => `+1 ${a} (${b}) ${c}-${d}`
  );

export const initObserver = () => {
  const recaptchaWindowArr = [
    ...document.getElementsByTagName("iframe"),
  ]?.filter((x) => x.src.includes("google.com/recaptcha/api2/bframe"));

  recaptchaWindowArr.forEach((iframe) => {
    const recaptchaWindow = iframe?.parentNode?.parentNode as HTMLDivElement;
    if (recaptchaWindow) {
      new MutationObserver(() => {
        // ReCaptcha changes these 3 props when going invisible.
        // To solve this, we put an observer on the attributes and
        // check if one of these 3 properties changed from their
        // initial value.
        if (
          recaptchaWindow.style.visibility !== "visible" ||
          recaptchaWindow.style.opacity !== "1" ||
          recaptchaWindow.style.top !== "10px"
        ) {
          // If changed, put back on default values.
          recaptchaWindow.style.opacity = "1";
          recaptchaWindow.style.visibility = "visible";
          recaptchaWindow.style.top = "10px";
        }
      }).observe(recaptchaWindow, {
        attributeFilter: ["style"],
      });
    }
  });
};

export const validateFiles = (files: File[], fileUploadType?: string) => {
  switch (fileUploadType) {
    case "VoidCheque":
      return (
        checkFilesTotalSize(files, 10 * 1024 * 1024) &&
        files.length < 2 &&
        checkFilesUniqueness(files)
      );
    default:
      return (
        checkFilesTotalSize(files, 100 * 1024 * 1024) &&
        files.length < 21 &&
        checkFilesUniqueness(files)
      );
  }
};

export function getFormattedDate(date: Date) {
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, "0");
  let day = date.getDate().toString().padStart(2, "0");

  return month + "/" + day + "/" + year;
}

export const checkFilesTotalSize = (files: File[], limit: number) => {
  const totalSize = files.reduce((a, b) => a + b.size, 0);
  return totalSize <= limit;
};

const checkFilesUniqueness = (files: File[]) => {
  const uniqueFilesLength = new Set(files.map((file) => file.name)).size;
  return uniqueFilesLength === files.length;
};

export const checkFileMimeType = (
  type: string,
  allowedTypes: string
): boolean => {
  const allowed = allowedTypes.split(",").map((x) => x.trim());
  return allowed.includes(type) || allowed.includes(type.split("/")[0] + "/*");
};

export function getFormattedCurrency(value?: number) {
  const languageString = i18n.language || window.localStorage.i18nextLng;
  const formattedCurrency =
    languageString === "fr"
      ? `${value?.toLocaleString("fr-CA", {
          maximumFractionDigits: 0,
        })} $`
      : `$${value?.toLocaleString("en-CA", {
          maximumFractionDigits: 0,
        })}`;
  return formattedCurrency;
}

export function getLastSevenMonthsLong() {
  const languageString = i18n.language || window.localStorage.i18nextLng;
  const formatter = new Intl.DateTimeFormat(languageString, { month: "long" });
  const sixth = formatter.format(
    new Date(new Date().setMonth(new Date().getMonth() - 1))
  );
  const fifth = formatter.format(
    new Date(new Date().setMonth(new Date().getMonth() - 2))
  );
  const fourth = formatter.format(
    new Date(new Date().setMonth(new Date().getMonth() - 3))
  );
  const third = formatter.format(
    new Date(new Date().setMonth(new Date().getMonth() - 4))
  );
  const second = formatter.format(
    new Date(new Date().setMonth(new Date().getMonth() - 5))
  );
  const first = formatter.format(
    new Date(new Date().setMonth(new Date().getMonth() - 6))
  );
  const zero = formatter.format(
    new Date(new Date().setMonth(new Date().getMonth() - 7))
  );

  return { zero, first, second, third, fourth, fifth, sixth };
}

export const clearLocalStorageScoringData = () => {
  localStorage.removeItem("FetchingTransactions");
  localStorage.removeItem("ReviewingBankingData");
  localStorage.removeItem("PreparingOffers");
};
