import Box from "@mui/material/Box";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import loader from "../../assets/loader.json";
import { LoadingProps } from "./loading-props";
import Backdrop from "@mui/material/Backdrop";

const Loading = ({ open }: LoadingProps) => {
  return (
    <Backdrop
      open={open}
      sx={{
        position: "fixed",
        zIndex: 9999,
        height: window.innerHeight,
      }}
    >
      <Box>
        <Lottie
          animationData={loader}
          loop
          style={{
            height: 100,
            width: "100%",
            display: open ? "inherit" : "none",
          }}
          play
        />
      </Box>
    </Backdrop>
  );
};

export default Loading;
