import { useEffect } from "react";
import i18n from "../locale/i18n";
import { useTranslation } from "react-i18next";

export const useDocumentTitle = () => {
  const { t } = useTranslation("documentTitle");

  const setDocumentTitle = () => {
    const exp = /\| [A-Za-z ]+$/;
    if (exp.test(document.title))
      document.title = document.title.replace(
        /\| [A-Za-z ]+$/,
        `| ${t("doctitle")}`
      );
    else document.title = t("doctitle");
  };

  useEffect(() => {
    setDocumentTitle();
  }, [[i18n.language, window.localStorage.i18nextLng]]);
};
