import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { applicationProgressState } from "../../recoil/application-progress/ApplicationProgressState";
import { step } from "../../hooks/configProgressStep";

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <LinearProgress variant="determinate" {...props} />
      </Box>
    </Box>
  );
}

const ProgressBar = () => {
  const progress = useRecoilValue(applicationProgressState);
  const [progressPercent, setProgressPercent] = useState(progress);

  useEffect(() => {
    const initialProgressPercent =
      progress >= progressPercent ? progress - step : progress + step;
    setProgressPercent(initialProgressPercent);
    let timer: ReturnType<typeof setTimeout>;
    for (let i = 0; i < step; i++) {
      timer = setTimeout(() => {
        setProgressPercent((prevPercent) =>
          progress >= progressPercent ? prevPercent + 1 : prevPercent - 1
        );
      }, 80 * (i + 1));
    }
    return () => clearTimeout(timer);
  }, [progress]);

  return progress > 0 ? (
    <Grid container spacing={1} justifyItems="space-between">
      <Grid item xs={12}>
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: 1,
            maxHeight: "14px",
            textAlign: "right",
            display: "flex",
            alignItems: "center",
            "& span": {
              width: "100%",
            },
          }}
        >
          <Typography
            variant="caption"
            color="white"
            fontWeight={600}
            sx={{
              transform: `translateX(-${100 - progress}%)`,
              transition: "transform 400ms linear",
              pr: 1,
            }}
          >
            {`${Math.round(progressPercent)}%`}
          </Typography>
        </Box>
        <LinearProgressWithLabel value={progress} sx={{ height: "12px" }} />
      </Grid>
    </Grid>
  ) : null;
};

export default ProgressBar;
