import Box from "@mui/material/Box";
import { SVGProps, useEffect, useState } from "react";
import styles from "./Animation.module.css";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    let timer = setTimeout(() => setShow(true), 10);

    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <Box height={props.height} width={props.width}>
      <svg
        width="100%"
        viewBox="0 0 415.954 100.696"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          stroke="#005887"
          strokeWidth={2}
          fill="#005887"
          d="M 77.810031,43.504115 C 75.317844,37.379116 73.036594,31.613491 70.544406,25.56271 c -7.625,9.894531 -15.105469,19.578125 -22.871093,29.542965 -3.492188,-8.04296 -6.914063,-15.875 -10.402344,-23.917965 -2.921875,3.914063 -5.699219,7.617185 -8.410156,11.320315 -5.414063,7.33203 -10.828126,14.66406 -16.242187,22.0664 -1.640625,2.20703 -3.3515618,4.27344 -4.8476558,6.55078 -1.066406,1.70704 -1.351563,1.13672 -1.992188,-0.28515 -3.066406,-6.69141 -4.632812,-13.66797 -4.488281,-21 C 1.6459702,35.246304 7.1303452,22.929897 18.103,13.246304 26.368625,5.914273 36.130344,1.925991 47.173313,1.144741 60.782687,0.1486471 72.751437,4.277554 83.153785,13.03146 c 3.49218,2.921875 6.41406,6.335937 8.90625,10.109375 0.42968,0.570312 1.07031,1.140625 0.28515,2.136719 -4.77343,5.910156 -9.546872,11.960937 -14.535154,18.226561 z m 0,0"
          className={`${styles["svg-elem-1"]} ${
            show ? styles["svg-elem-1-active"] : ""
          }`}
        />
        <path
          stroke="#4ab580"
          strokeWidth={2}
          fill="#4ab580"
          d="M67.478 46.918c2.352 5.91 4.633 11.465 7.055 17.445 7.84-9.753 15.46-19.222 23.156-28.835 1.21 2.636 1.64 5.34 1.996 7.976 1.211 8.899.14 17.512-3.348 25.77C91.85 79.883 84.51 87.996 74.392 93.62c-8.621 4.7-17.883 6.692-27.574 5.91-9.477-.785-18.098-3.988-25.723-9.753-2.492-1.852-4.773-3.918-6.91-6.196-.926-.926-1.07-1.707-.215-2.918 6.63-8.828 13.11-17.656 19.664-26.484.36-.426.57-1.137 1.14-1.067.641.07.716.782.927 1.282 2.851 6.62 5.703 13.312 8.55 19.933.5 1.211 1 1.422 1.852.285 5.062-6.62 10.121-13.242 15.25-19.863a507.512 507.512 0 0 0 6.125-7.832zm0 0"
          className={`${styles["svg-elem-2"]} ${
            show ? styles["svg-elem-2-active"] : ""
          }`}
        />
        <path
          stroke="#1A1A1A"
          strokeWidth={2}
          d="M258.287 92.125c-.856-1.496-1.352-3.133-1.922-4.77-3.137-9.038-6.27-18.152-9.407-27.195-.14-.355-.214-.71.145-.925 1.992-.141 3.988-.286 5.91.074 2.496 7.758 4.988 15.52 7.485 23.277h.496c2.566-7.758 5.132-15.52 7.695-23.277a15.93 15.93 0 0 1 5.203 0c.926 3.914 2.422 7.617 3.633 11.39a575.933 575.933 0 0 1 3.348 10.536c.144.496.214.925.855 1.421.715-2.132 1.496-4.199 2.137-6.265 1.781-5.551 3.562-11.176 5.343-16.727.075-.144.215-.285.36-.43 1.992-.14 3.988-.285 5.91.075-1.992 5.765-3.918 11.531-5.984 17.226-1.922 5.196-3.348 10.535-5.63 15.59-1.636.145-3.202.215-4.843-.07-2.637-7.477-5.2-14.95-7.836-22.426-.144 0-.285 0-.43-.07-2.636 7.476-5.199 15.02-7.836 22.496-1.425.285-3.066.215-4.632.07zm0 0"
          fillOpacity={0.898}
          className={`${styles["svg-elem-3"]} ${
            show ? styles["svg-elem-3-active"] : ""
          }`}
        />
        <path
          stroke="#1A1A1A"
          strokeWidth={2}
          d="M155.689 92.48c-7.91 0-14.395-5.34-15.89-13.097-1.923-9.969 4.417-19.293 14.034-20.504.57-.285 1.286.145 1.856-.285h1.496c.781.5 1.707-.07 2.563.285 2.992.57 5.773 1.637 8.265 3.418.856.57.996.996.215 1.852-1 .996-1.64 2.632-2.922 2.988-1.21.355-2.281-1.137-3.492-1.707-6.625-3.133-13.61-.285-15.676 6.476-.14.57-.21 1.07-.21 1.641a9.716 9.716 0 0 0 0 4.055c.07 4.77 4.488 9.398 9.261 9.683 2.707.215 5.273-.285 7.695-1.566 1.211-.64 1.782-1.352 1.711-2.777-.144-1.282-.074-2.563-.074-3.914-2.066-.43-4.133-.07-6.125-.215-.64-.07-1.355.144-1.996-.14l-.07-.075c-.07-.14-.145-.211-.145-.356-.285-4.695-.285-4.695 4.348-4.625 3.207.07 6.414-.214 9.617.141V85.86c0 1-.211 1.64-1.066 2.351-3.493 2.633-7.34 3.985-11.614 4.27-.57.215-1.14.215-1.781 0zm0 0"
          fillOpacity={0.898}
          className={`${styles["svg-elem-4"]} ${
            show ? styles["svg-elem-4-active"] : ""
          }`}
        />
        <path
          stroke="#1A1A1A"
          strokeWidth={2}
          d="M337.662 72.621c4.629 0 9.261 0 13.89.07 1.211 0 1.426-.57 1.426-1.566-.07-3.347 0-6.691 0-10.039 0-.64-.215-1.351.43-1.922a20.487 20.487 0 0 1 5.129 0c.64.5.425 1.282.425 1.922 0 9.54 0 19.078.075 28.621 0 1.848-.57 2.348-2.352 2.278-3.637-.075-3.637 0-3.637-3.633 0-2.848-.07-5.696 0-8.543 0-1.137-.21-1.707-1.496-1.637-4.558.07-9.191.07-13.82.07-.43 2.922-.07 5.77-.215 8.688-.07 1.566.145 3.058-.14 4.625-1.926.64-3.848.64-5.704 0-.285-1.281-.07-2.563-.14-3.844v-26.48c0-.641-.145-1.356.14-1.996l.075-.07a24.015 24.015 0 0 1 5.554 0l.074.07c.356 3.773.07 7.546.141 11.39 0 .715-.215 1.356.145 1.996zm0 0"
          fillOpacity={0.898}
          className={`${styles["svg-elem-5"]} ${
            show ? styles["svg-elem-5-active"] : ""
          }`}
        />
        <path
          stroke="#1A1A1A"
          strokeWidth={2}
          d="M315.357 64.86c0 8.328-.07 16.73 0 25.058 0 1.781-.496 2.207-2.207 2.137-3.848-.145-3.848 0-3.848-3.844 0-7.832-.07-15.59-.07-23.422-2.566-.426-5.129-.07-7.625-.215-.785-.07-1.566.145-2.352-.14l-.07-.07a21.05 21.05 0 0 1 0-5.2l.07-.07c1-.285 2.067-.145 3.137-.145h20.945c.641 0 1.356-.14 1.996.145.641 1.777.641 3.559 0 5.34-2.636.285-5.343 0-7.98.14-.64.145-1.352-.07-1.996.286zm0 0"
          fillOpacity={0.898}
          className={`${styles["svg-elem-6"]} ${
            show ? styles["svg-elem-6-active"] : ""
          }`}
        />
        <path
          stroke="#1A1A1A"
          strokeWidth={2}
          d="M166.947 18.727c-2.996 4.414-6.059 8.758-8.98 13.242-.856 1.355-1.141 1.281-1.993 0-2.781-4.34-5.773-8.543-8.621-12.813-.855.426-.43 1.211-.43 1.778-.07 6.695 0 13.387-.07 20.078-1.996.71-3.918.71-5.914 0 0-10.75-.07-21.57.07-32.32l.07-.07c1.997-.145 3.922-.075 5.915-.075 2.992 4.559 5.984 9.113 8.906 13.742 1 1.567 1.21 1.637 2.21.07 2.638-4.128 5.274-8.257 7.907-12.456.36-.57.785-.926 1.211-1.356 1.996.074 3.922-.21 5.914.145 0 10.32-.07 20.644.074 30.968 0 1.563-.43 2.063-1.996 1.848-1.21-.14-2.492 0-3.707 0-.285-2.492-.14-5.055-.14-7.543-.07-4.488 0-9.043 0-13.527 0-.64.425-1.352-.426-1.711zm0 0"
          fillOpacity={0.898}
          className={`${styles["svg-elem-7"]} ${
            show ? styles["svg-elem-7-active"] : ""
          }`}
        />
        <path
          stroke="#1A1A1A"
          strokeWidth={2}
          d="M381.623 8.477c.71.5.425 1.281.425 1.922 0 9.683 0 19.363.07 28.976 0 1.707-.57 1.992-2.136 2.063-2.207.07-3.418-.57-4.774-2.422-4.703-6.406-9.761-12.672-14.675-18.934-.285-.43-.36-1.07-1.211-1.281v9.824c0 3.63-.074 7.332 0 10.961 0 1.426-.43 1.781-1.856 1.781-4.133 0-4.133.07-4.133-4.129V10.47c0-.711-.355-1.492.43-2.063h4.703c2.207 2.348 4.059 5.055 5.985 7.547 3.703 4.7 7.34 9.395 10.972 14.094.07.145.211.356.57.07V10.4c0-.711-.359-1.496.426-2.063 1.782.14 3.493.14 5.204.14zm0 0"
          fillOpacity={0.898}
          className={`${styles["svg-elem-8"]} ${
            show ? styles["svg-elem-8-active"] : ""
          }`}
        />
        <path
          stroke="#1A1A1A"
          strokeWidth={2}
          d="M308.232 8.621c0 10.32-.07 20.645.074 30.965 0 1.567-.43 2.067-1.996 1.852-1.21-.14-2.492 0-3.707 0-.355-3.844-.07-7.758-.14-11.676 0-.64.214-1.352-.215-1.992-4.774-.07-9.547-.07-14.32-.14-1.141 0-.927.71-.927 1.35 0 3.56-.07 7.048 0 10.606 0 1.356-.355 1.852-1.78 1.852-4.204 0-4.204.07-4.204-4.059V10.613c0-.71-.355-1.496.426-2.066h5.129c.715.5.43 1.281.43 1.922v10.394c0 .711-.07 1.352.996 1.282 4.773-.07 9.546-.14 14.324-.14.426-2.778.07-5.556.21-8.259.071-1.636-.07-3.347.071-4.984l.074-.07c1.781-.215 3.633-.356 5.555-.07zm0 0"
          fillOpacity={0.898}
          className={`${styles["svg-elem-9"]} ${
            show ? styles["svg-elem-9-active"] : ""
          }`}
        />
        <path
          stroke="#1A1A1A"
          strokeWidth={2}
          d="M205.99 36.168c-.14 1.711.71 3.988-.356 4.914-.926.856-3.136.285-4.843.285h-19.098c-.215-.425-.14-.855-.14-1.28V9.472c0-.285 0-.64.07-.926l.07-.07c7.98-.14 15.89-.14 23.87 0l.071.07c.07 1.711.215 3.488-.07 5.2-5.559 0-11.117.07-16.746 0-1.352 0-1.711.355-1.637 1.636.14 1.566.14 3.203 0 4.84-.144 1.566.496 1.781 1.852 1.781 4.132-.07 8.265.07 12.468-.07 1.782-.074 2.422.566 2.137 2.207-.14 1.066.43 2.203-.426 3.203h-14.039c-.57 0-1.21-.215-1.78.21a53.045 53.045 0 0 0 0 8.188c1.14.426 2.35.145 3.491.215 5.13.14 10.117-.144 15.106.211zm0 0"
          fillOpacity={0.898}
          className={`${styles["svg-elem-10"]} ${
            show ? styles["svg-elem-10-active"] : ""
          }`}
        />
        <path
          stroke="#1A1A1A"
          strokeWidth={2}
          d="M265.271 8.121c2.922.926 5.914 1.782 8.336 3.844.781.711 1.426 1.211.285 2.066-.285.282-.43.711-.715 1.067-1.851 2.207-1.922 2.277-4.273.64-3.988-2.777-9.547-3.417-13.61-.07-2.28 1.852-3.562 4.2-3.847 7.117a9.735 9.735 0 0 0 0 4.059c0 4.484 4.277 9.113 8.62 9.328a9.886 9.886 0 0 0 4.063 0c2.207-.285 3.989-1.496 5.63-2.848 1.066-.855 1.78-1 2.706.141.711.926 1.637 1.637 2.492 2.422-5.27 6.621-16.527 7.902-23.011 2.918-4.278-3.203-6.625-7.332-6.84-12.742-.145-3.348.285-6.551 1.922-9.543 2.566-4.625 6.414-7.332 11.613-8.328.145 0 .215-.07.36-.141.57-.145 1.21.21 1.78-.215h2.634c.644.5 1.285.14 1.855.285zm0 0"
          fillOpacity={0.898}
          className={`${styles["svg-elem-11"]} ${
            show ? styles["svg-elem-11-active"] : ""
          }`}
        />
        <path
          stroke="#1A1A1A"
          strokeWidth={2}
          d="M404.208 41.012c-1.855.926-3.847.21-5.773.426-.285-.711-.14-1.496-.14-2.207 0-7.832-.07-15.59 0-23.422 0-1.352-.286-1.852-1.712-1.778-2.851.07-5.699.141-8.55-.074l-.07-.07a32.041 32.041 0 0 1 0-5.195l.07-.07a810.335 810.335 0 0 1 26.508 0l.07.07c.14 1.707.14 3.488 0 5.195l-.07.07c-2.852.145-5.704.145-8.551.074-1.426 0-1.782.356-1.782 1.778.07 8.402 0 16.8 0 25.203zm0 0"
          fillOpacity={0.898}
          className={`${styles["svg-elem-12"]} ${
            show ? styles["svg-elem-12-active"] : ""
          }`}
        />
        <path
          stroke="#1A1A1A"
          strokeWidth={2}
          d="M231.783 28.91c4.558-1.425 6.98-3.847 7.695-8.332.57-3.558-.43-6.832-3.348-9.324-2.21-1.851-4.847-2.207-7.41-2.777h-15.535c-.71.57-.426 1.351-.426 2.066V37.31c0 4.129 0 4.129 4.203 4.129 1.356 0 1.852-.426 1.852-1.852-.07-2.848 0-5.695 0-8.543 0-.496-.211-1.21.715-1.21h5.77c.57 0 .784.5 1.07.855 2.277 3.277 4.629 6.55 6.98 9.824.285.355.43.785.926.785h6.629c-3.352-4.344-6.2-8.332-9.121-12.387zm-9.262-4.273c-3.777.07-3.777 0-3.777-3.703v-1.992c.214-1.496-.786-3.844.355-4.84 1-.856 3.277-.285 5.059-.285 1.781 0 3.562-.215 5.343.285 2.852.71 4.063 2.207 4.133 4.91.07 2.848-1.21 4.484-3.918 5.27-2.422.71-4.843.285-7.195.355zm0 0"
          fillOpacity={0.898}
          className={`${styles["svg-elem-13"]} ${
            show ? styles["svg-elem-13-active"] : ""
          }`}
        />
        <path
          stroke="#1A1A1A"
          strokeWidth={2}
          d="M244.107 73.828c-.926-7.902-6.055-13.312-14.25-15.02-.711-.355-1.496.141-2.207-.284h-1.14c-.712.425-1.497 0-2.208.285-7.48.855-13.754 7.262-14.25 14.594a15.196 15.196 0 0 0 0 4.414c.496 7.546 7.766 14.593 15.746 14.593.711.215 1.426.215 2.207 0 8.551-.5 15.106-6.761 16.102-15.375.215-1 .215-2.066 0-3.207zM227.08 87.215c-6.13.07-11.399-5.555-10.973-11.746-.285-6.195 4.918-11.465 11.258-11.39 5.488.07 10.972 5.62 10.832 11.671-.285 6.48-4.848 11.39-11.117 11.465zm0 0"
          fillOpacity={0.898}
          className={`${styles["svg-elem-14"]} ${
            show ? styles["svg-elem-14-active"] : ""
          }`}
        />
        <path
          stroke="#1A1A1A"
          strokeWidth={2}
          d="M204.634 90.844c-2.277-3.273-4.488-6.621-6.98-9.754-1.14-1.422-.5-1.637.64-2.133 6.485-2.777 7.696-10.254 4.204-15.379-2.278-3.347-5.915-3.988-9.618-4.343-1.925-.286-3.777-.141-5.699-.07-3.207.07-6.414-.216-9.547.144v30.398c0 2.348 0 2.348 2.422 2.348h3.133c.285-.57.144-1.211.144-1.852v-8.187c0-.64-.144-1.422.926-1.352 2.067.14 4.203-.215 6.27.215a508.785 508.785 0 0 1 7.125 10.11c.5.71 1.07 1.136 1.996 1.066 1.422-.07 2.848 0 4.273 0 .356 0 .785.14.996-.285.36-.426-.07-.64-.285-.926zm-11.328-15.59c-2.922.07-5.84 0-8.762.07-.925 0-1.355-.285-1.355-1.28.07-2.778 0-5.552 0-8.329 0-.855.355-1.21 1.21-1.21 1.497.07 2.993 0 4.774 0 1.496.07 3.278-.216 5.059.214 2.922.71 4.418 2.918 4.207 5.906-.074 2.352-2.426 4.559-5.133 4.63zm0 0"
          fillOpacity={0.898}
          className={`${styles["svg-elem-15"]} ${
            show ? styles["svg-elem-15-active"] : ""
          }`}
        />
        <path
          stroke="#1A1A1A"
          strokeWidth={2}
          d="M344.501 33.75a2551.232 2551.232 0 0 1-10.546-24.418c-.215-.5-.356-1.14-1.211-1.14-1.282.07-2.637 0-3.918 0-.57 0-.926.214-1.141.71-.855 1.922-1.71 3.919-2.566 5.84-3.348 7.618-6.555 15.235-9.973 22.852-1.711 3.773-1.781 3.629 2.352 3.773 1.496.07 2.277-.425 2.918-1.851.855-1.992 1.425-4.27 2.851-6.05 1.426-.427 2.922-.216 4.418-.142 3.563.07 7.125-.285 10.688.141.925 2.137 1.78 4.2 2.777 6.336.285.71.5 1.566 1.57 1.496 1.637-.074 3.348 0 5.2 0a222.88 222.88 0 0 1-3.419-7.547zm-9.69-5.48c-2.778-.074-5.56 0-8.337 0-.57 0-1.355.21-.855-.926 1.71-3.848 3.347-7.762 5.058-11.89 1.781 4.058 3.348 7.761 5.059 11.46.43.996.355 1.356-.926 1.356zm0 0"
          fillOpacity={0.898}
          className={`${styles["svg-elem-16"]} ${
            show ? styles["svg-elem-16-active"] : ""
          }`}
        />
      </svg>
    </Box>
  );
};

export default SvgComponent;
