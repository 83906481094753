import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useEffect } from "react";
import i18n from "../../locale/i18n";
import { setLanguage } from "../../api/customer";
import { checkIfTokenIsExpired } from "../../utils";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { applicationInfoState } from "../../recoil/application-info/ApplicationInfoState";
import { loadingState } from "../../recoil/loading/loadingState";

const LanguageToggle = () => {
  const { language } = useRecoilValue(applicationInfoState);
  const languageLocal = i18n.language || window.localStorage.i18nextLng;
  const setLoading = useSetRecoilState(loadingState);
  const token = localStorage.getItem("token");

  useEffect(() => {
    (async () => {
      if (token && !checkIfTokenIsExpired(token) && language) {
        await setLanguage(language);
        i18n.changeLanguage(language);
      }
    })();
  }, []);

  const handleLanguageChange = async (lng: string) => {
    setLoading(true);
    if (token && !checkIfTokenIsExpired(token)) await setLanguage(lng);
    i18n.changeLanguage(lng);
    setLoading(false);
  };

  return (
    <Box>
      <Box className="mask-box">
        <Box
          className="mask"
          style={{
            transform: `translateX(${languageLocal !== "fr" ? 0 : "50px"})`,
          }}
        />
        <Button
          disableRipple
          variant="text"
          sx={{
            color: languageLocal !== "fr" ? "#ffffff" : "primary",
            minHeight: 0,
            minWidth: 0,
            padding: 0,
            mt: "2px",
          }}
          onClick={() => handleLanguageChange("en")}
        >
          En
        </Button>
        <Button
          disableRipple
          variant="text"
          sx={{
            color: languageLocal === "fr" ? "#ffffff" : "primary",
            minHeight: 0,
            minWidth: 0,
            padding: 0,
            mt: "2px",
          }}
          onClick={() => handleLanguageChange("fr")}
        >
          Fr
        </Button>
      </Box>
    </Box>
  );
};

export default LanguageToggle;
