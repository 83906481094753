import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import routes from "./routes/routes";
import { MemoryRouter as Router, Route, Routes } from "react-router-dom";
import SvgComponent from "./components/animated-logo";
import { Suspense, useEffect, useState } from "react";
import Loading from "./components/loading";
import { useRecoilValue } from "recoil";
import { useIdleTimer } from "react-idle-timer";
import { checkIfTokenIsExpired } from "./utils";
import { personalInfoState } from "./recoil/personal-info/PersonalInfoFormState";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { useMobileOrDesktop } from "./hooks/screen-size-hook";
import ProgressBar from "./components/progress-bar";
import LanguageToggle from "./components/language-toggle";
import { useTranslation } from "react-i18next";
import { useDocumentTitle } from "./hooks/document-title-hook";
import { loadingState } from "./recoil/loading/loadingState";
import { setBearerAuthToken } from "./api/axios";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";

const App = () => {
  const [show, setShow] = useState(true);
  const expired = localStorage.getItem("expired");
  const token = localStorage.getItem("token");
  const adminView = localStorage.getItem("adminView");
  const personalInfo = useRecoilValue(personalInfoState);
  const loading = useRecoilValue(loadingState);
  const desktop = useMobileOrDesktop();
  const [open, setOpen] = useState(expired === "true");
  const { t } = useTranslation("emailVerification");

  const handleOnIdle = () => {
    if (token) {
      handleLogout();
    }
  };

  const handleClose = () => {
    localStorage.removeItem("expired");
    setOpen(false);
  };

  useDocumentTitle();

  useEffect(() => {
    (async () => {
      if (token && !checkIfTokenIsExpired(token)) {
        setBearerAuthToken(token);
        reset();
      }
    })();
  }, [personalInfo]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    if (adminView) {
      localStorage.removeItem("adminView");
      window.location.href = "/admin";
    } else {
      localStorage.setItem("expired", "true");
      window.location.href = "/";
    }
  };

  const { reset } = useIdleTimer({
    timeout: 1000 * 60 * 10,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  useEffect(() => {
    let timer = setTimeout(() => setShow(false), 1500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (navigator.userAgent.indexOf("iPhone") > -1) {
    const viewport = document.querySelector("[name=viewport]");
    viewport?.setAttribute(
      "content",
      "width=device-width, initial-scale=1, maximum-scale=1"
    );
  }
  const shutdownDate = new Date(+import.meta.env.VITE_SHUTDOWN_DATE_ALL);
  const currentDate = new Date();
  const showDeadlinePassed = currentDate >= shutdownDate;

  let initialPage;
  switch (window.location.pathname) {
    case "/summary":
      initialPage = "/summary";
      break;
    case "/admin":
      initialPage = "/admin";
      break;
    default:
      initialPage = "/";
      break;
  }

  if (showDeadlinePassed) initialPage = "/deadline-passed";

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        height: `${window.innerHeight}px`,
        backgroundColor: "#F0F0F0",
      }}
    >
      {show ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: `${window.innerHeight}px`,
            mx: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              bgcolor: "#F0F0F0",
              p: 3,
              borderRadius: "4px",
            }}
          >
            <SvgComponent width={desktop ? 500 : 300} />
          </Box>
        </Box>
      ) : (
        <>
          <AppBar position="fixed" sx={{ zIndex: 1300 }}>
            <Toolbar
              sx={{
                bgcolor: "white",
                py: 1,
                justifyContent: "space-between",
              }}
            >
              <img height={40} src="logo-full.svg" />
              {adminView ? (
                <>
                  <Typography variant="h5" fontWeight={600} color="red">
                    Admin View
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ color: "white" }}
                    onClick={handleLogout}
                  >
                    Logout
                  </Button>
                </>
              ) : (
                <LanguageToggle />
              )}
              <Loading open={loading} />
            </Toolbar>
            <ProgressBar />
          </AppBar>
          <Router initialEntries={[initialPage]}>
            <Suspense fallback={<Loading open={true} />}>
              <Routes>
                {routes.map((route) => (
                  <Route
                    path={route.path}
                    key={route.path}
                    element={
                      route.path === "/sign-agreement" ||
                      route.path === "/id-verification" ? (
                        <route.component />
                      ) : desktop ? (
                        <Dialog
                          open={true}
                          maxWidth="sm"
                          fullWidth
                          scroll="body"
                          hideBackdrop={true}
                          sx={{
                            "& .MuiDialog-container": {
                              justifyContent: "flex-start",
                              alignItems: "flex-start",
                              width: "100%",
                            },
                            zIndex: 1200,
                          }}
                          PaperProps={{
                            sx: {
                              boxShadow: loading ? "none" : "auto",
                              mt: 14,
                            },
                          }}
                        >
                          <route.component />
                        </Dialog>
                      ) : (
                        <Box
                          sx={{
                            pt: 9,
                            bgcolor: "#FFF",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <route.component />
                        </Box>
                      )
                    }
                  />
                ))}
              </Routes>
            </Suspense>
          </Router>
        </>
      )}
      <Snackbar
        open={open}
        autoHideDuration={9999999999999}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {t("loggedOut")}
        </Alert>
      </Snackbar>
      {adminView ? (
        <Backdrop
          sx={{ backgroundColor: "rgba(0,0,0, 0.1)", zIndex: 1250 }}
          open={true}
        />
      ) : null}
    </Box>
  );
};

export default App;
