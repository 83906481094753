import { axios } from "./axios";
import { components } from "../types/swagger-types";

const ENDPOINT = "/customer";

type CustomerCreateRequest = components["schemas"]["CustomerCreateRequest"];
type PersonalDetailsRequest = components["schemas"]["PersonalDetailsRequest"];
type BusinessDetailsRequest = components["schemas"]["BusinessDetailsRequest"];
type BankingDetailsRequest = components["schemas"]["BankingDetailsRequest"];
type LoanDetailsRequest = components["schemas"]["LoanAmountsRequest"];
type LoanBankRequest = components["schemas"]["LoanBankRequest"];
type ConfirmBankingInformationRequest =
  components["schemas"]["ConfirmBankingInformationRequest"];
export type BankingInformationConfirmationResponse =
  components["schemas"]["BankingInformationConfirmationResponse"];

export async function sendPersonalInfo({
  firstName,
  lastName,
  dateOfBirth,
  phoneNumber,
  sin,
  address,
  agreedToInfoDisclosure,
}: PersonalDetailsRequest) {
  const { data }: { data: any } = await axios.put(
    ENDPOINT + "/personal-information",
    {
      firstName,
      lastName,
      dateOfBirth,
      phoneNumber,
      sin,
      address,
      agreedToInfoDisclosure,
    }
  );
  return data;
}

export async function sendBusinessInfo({
  industry,
  subIndustry,
  timeInBusiness,
  tradeName,
  legalBusinessName,
  entityType,
  address,
}: BusinessDetailsRequest) {
  const { data }: { data: any } = await axios.put(
    ENDPOINT + "/business-information",
    {
      industry,
      subIndustry,
      timeInBusiness,
      tradeName,
      legalBusinessName,
      entityType,
      address,
    }
  );
  return data;
}

export async function sendAnnualRevenueInfo({
  annualRevenue,
}: {
  annualRevenue: number;
}) {
  const { data }: { data: any } = await axios.put(
    ENDPOINT + "/annual-revenue",
    {
      annualRevenue,
    }
  );
  return data;
}

export async function sendBankingInfo({
  identifier,
  outcome,
}: BankingDetailsRequest) {
  const { data }: { data: any } = await axios.put(
    ENDPOINT + "/banking-information",
    {
      identifier,
      outcome,
    }
  );
  return data;
}

export async function sendLoanInfo({
  hasPayments,
  initialLoanAmount,
  remainingLoanBalance,
  loanFinId,
  loanFinName,
}: LoanDetailsRequest) {
  const { data }: { data: any } = await axios.put(
    ENDPOINT + "/loan-information",
    {
      hasPayments,
      initialLoanAmount,
      remainingLoanBalance,
      loanFinId,
      loanFinName,
    }
  );
  return data;
}

export async function sendLoanBankInfo({
  loanInstitution,
  transitNumber,
  accountNumber,
  paymentType,
}: LoanBankRequest) {
  const { data }: { data: any } = await axios.put(
    ENDPOINT + "/loan-bank-details",
    {
      loanInstitution,
      transitNumber,
      accountNumber,
      paymentType,
    }
  );
  return data;
}

export async function sendRealTimeBankInfo(step: string, institution: string) {
  const { data }: { data: any } = await axios.post(
    ENDPOINT + "/banking-real-time",
    {
      step,
      institution,
    }
  );
  return data;
}

export async function sendSelectedBankAccount(selectedBankAccount: string) {
  const { data }: { data: any } = await axios.put(ENDPOINT + "/bank-account", {
    selectedBankAccount,
  });
  return data;
}

export async function getBankingInformation() {
  const { data }: { data: BankingInformationConfirmationResponse } =
    await axios.get(ENDPOINT + "/banking-information-confirmation");
  return data;
}

export async function sendBankingInformation({
  loanInstitution,
  loanTransit,
  loanAccount,
  loanAccountType,
}: ConfirmBankingInformationRequest) {
  const { data }: { data: any } = await axios.put(
    ENDPOINT + "/banking-information-confirmation",
    {
      loanInstitution,
      loanTransit,
      loanAccount,
      loanAccountType,
    }
  );
  return data;
}

export async function confirmInformation() {
  const { data }: { data: any } = await axios.post(ENDPOINT + "/confirm");
  return data;
}

export async function setManualUpload() {
  const { data }: { data: any } = await axios.post(
    ENDPOINT + "/set-manual-upload"
  );
  return data;
}

export async function acceptAcknowledgement() {
  const { data }: { data: any } = await axios.post(
    ENDPOINT + "/acknowledgement"
  );
  return data;
}

export async function createCustomer({
  utmSource,
  utmMedium,
  utmCampaign,
  utmTerm,
  userId,
  googleAnalyticsCookie,
  language,
}: CustomerCreateRequest) {
  const { data }: { data: CustomerCreateRequest } = await axios.post(
    ENDPOINT + "/create",
    {
      applicationType: 0,
      utmSource,
      utmMedium,
      utmCampaign,
      utmTerm,
      userId,
      googleAnalyticsCookie,
      language,
    }
  );
  return data;
}

export async function setLanguage(language: string) {
  const { data }: { data: CustomerCreateRequest } = await axios.post(
    ENDPOINT + "/set-language",
    {
      language,
    }
  );
  return data;
}
