import ReactDOM from "react-dom/client";
import "./locale/i18n";
import { ThemeProvider } from "@mui/material/styles";
import { RecoilRoot } from "recoil";
import theme from "./theme";
import App from "./App";
import "./index.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/audiowide/400.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import Smartlook from "smartlook-client";
import { QueryClient, QueryClientProvider } from "react-query";
import { LiveChatLoaderProvider } from "react-live-chat-loader";

const queryClient = new QueryClient();

if (
  import.meta.env.VITE_SMARTLOOK_PROJECT_KEY &&
  window.location.pathname !== "/admin" &&
  !localStorage.getItem("adminView")
) {
  Smartlook.init(import.meta.env.VITE_SMARTLOOK_PROJECT_KEY);
  Smartlook.record({ forms: true, numbers: true, emails: false, ips: false });
  Smartlook.properties({ appVersion: import.meta.env.VITE_APP_VERSION });
}

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [new BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  environment: import.meta.env.NODE_ENV,
  release: import.meta.env.VITE_APP_VERSION,
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <QueryClientProvider client={queryClient}>
    <LiveChatLoaderProvider
      providerKey={import.meta.env.VITE_HELPSCOUT_BEACON_KEY}
      provider="helpScout"
    >
      <RecoilRoot>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </RecoilRoot>
    </LiveChatLoaderProvider>
  </QueryClientProvider>
);
